import React from "react";

const Services = () => {
    var webd = "web.png";
    var eweb = "ecweb.jpg";
    var reweb = "reweb.jpg";
    var crmweb = "crmweb.png";
    var weblay = "weblay.png";
    var webbro = "webbro.png";
    var soweb = "soweb.jpg";
    var payweb = "payweb.jpg";
    var parweb = "parweb.jpg";
    var staweb = "staweb.jpg";
    var api = "api.png";
    var videoe = "video.png";
    return (
        <div className="about">
            <div class="jh_bg grads">
                <ul class="jh_squares">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className="head">
                Services
            </div>

            <div className="services_list">
                <ul>
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+webd} alt={webd}/>
                            </div>
                            <div className="li_text">
                                Website Development
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+eweb} alt={eweb}/>
                            </div>
                            <div className="li_text">
                                E-Commerce Website
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+reweb} alt={reweb}/>
                            </div>
                            <div className="li_text">
                                Responsive Design
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+crmweb} alt={crmweb}/>
                            </div>
                            <div className="li_text">
                                CRM Development
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+weblay} alt={weblay}/>
                            </div>
                            <div className="li_text">
                                Web <br></br>Layouts
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+webbro} alt={webbro}/>
                            </div>
                            <div className="li_text">
                                Graphic <br></br>Design
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+soweb} alt={soweb}/>
                            </div>
                            <div className="li_text">
                                Social <br></br>Integration
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+payweb} alt={payweb}/>
                            </div>
                            <div className="li_text">
                                Payment Gateway Integration
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+parweb} alt={parweb}/>
                            </div>
                            <div className="li_text">
                                Parallax Design
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+staweb} alt={staweb}/>
                            </div>
                            <div className="li_text">
                                Static Website
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+api} alt={api}/>
                            </div>
                            <div className="li_text">
                                API <br></br>Integration
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                    <li>{/* Loops Starts */}
                        <div className="li_box">
                            <div className="li_img">
                                <img src={"../../images/"+videoe} alt={videoe}/>
                            </div>
                            <div className="li_text">
                                Video and Audio Editing
                            </div>
                        </div>
                    </li>{/* Loops Ends */}
                </ul>
            </div>

        </div>
    );
}

export default Services;