import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppRoutes from "./app_routes";
import Home from "../screens/web/home";
import ProfileTem from "./layouts/profile";
import About from "../screens/web/about";
import Skills from "../screens/web/skills";
import Experience from "../screens/web/experience";
import Services from "../screens/web/services";
import Contact from "../screens/web/contact";


const Main_nav = () =>{
    return(
       <BrowserRouter>
        <Routes>
            <Route path='/' element={<AppRoutes component={Home}></AppRoutes>}></Route>
            <Route path='about-me' element={<AppRoutes layout={ProfileTem} component={About}></AppRoutes>}></Route>
            <Route path='skills' element={<AppRoutes layout={ProfileTem} component={Skills}></AppRoutes>}></Route>
            <Route path='experience' element={<AppRoutes layout={ProfileTem} component={Experience}></AppRoutes>}></Route>
            <Route path='services' element={<AppRoutes layout={ProfileTem} component={Services}></AppRoutes>}></Route>
            <Route path='contact' element={<AppRoutes layout={ProfileTem} component={Contact}></AppRoutes>}></Route>
        </Routes>
       </BrowserRouter>
    );
}

export default Main_nav;