import React from "react";

const Experience = () =>{
    return(
        <div className="about">
            <div class="jh_bg grads">
                <ul class="jh_squares">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>

            <div className="head">
                Professional Experience
            </div>

            <div className="years_cols">
                <div className="cols_one cols_two">
                    <div className="time_line">
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2021-Present</span>
                                <span></span>
                            </div>
                            <div className="name">
                                <span>Software Developer</span>
                            </div> 
                            <div className="company">
                                <span>Dr.Kamakshi Memorial Hospitals </span>
                            </div>    
                            <div className="para_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Spearheaded the development and maintenance of the hospital's web presence, ensuring a seamless and efficient user experience.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Designed and implemented user interfaces using HTML, CSS, JavaScript, and jQuery, focusing on accessibility and responsive design.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Developed and maintained server-side functionalities using PHP, MySQL, and AJAX, enhancing website performance and scalability.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Integrated various APIs for enhanced data processing and user interaction, ensuring smooth operation and data accuracy.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Leveraged Premium Pro for advanced editing and content creation, enhancing the visual appeal of the website.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Utilized MongoDB for efficient data management, ensuring high performance in data retrieval and storage.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Developed applications using the MERN stack (MongoDB, Express.js, React, Node.js), providing robust and scalable web solutions.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Created and maintained mobile applications using React Native, expanding the hospital's reach to mobile users.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Collaborated with cross-departmental teams to understand requirements and deliver tailored web solutions.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Ensured high standards of web security and compliance with healthcare regulations.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in configuring .htaccess files for Apache server, enhancing website security, and optimizing URL rewriting and redirection. Proficient in utilizing Postman for API development, testing RESTful services, and automating API workflows and tests. Skilled in debugging HTTP requests, analyzing responses, and integrating APIs with front-end and back-end systems
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2014-2021</span>
                                <span></span>
                            </div>
                            <div className="name">
                                <span>Web Designer and Developer </span>
                            </div> 
                            <div className="company">
                                <span>Freelance Consultant</span>
                            </div>    
                            <div className="para_list">
                                <ul>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Led the design and development of responsive websites, ensuring compatibility across various browsers and devices.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Expertly coded websites using HTML, CSS, and JavaScript, focusing on user experience and interactive features.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Implemented dynamic website functionalities using jQuery and AJAX, enhancing user engagement.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Developed robust backend solutions with PHP and MySQL, ensuring data security and efficient server-side processing.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Conducted website testing and debugging, ensuring high performance and minimal downtime.


                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Managed multiple projects simultaneously, consistently meeting deadlines and exceeding client expectations.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Stayed abreast of the latest web design trends and technologies, applying them to projects as appropriate.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Provided ongoing maintenance and updates to existing websites, improving functionality and user experience.

                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in configuring .htaccess files for Apache server, enhancing website security, and optimizing URL rewriting and redirection. Proficient in utilizing Postman for API development, testing RESTful services, and automating API workflows and tests. Skilled in debugging HTTP requests, analyzing responses, and integrating APIs with front-end and back-end systems
                                        </div>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>

                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>Freelancer</span>
                                <span></span>
                            </div>
                            <div className="name">
                                <span>UX Developer  </span>
                            </div> 
                            <div className="company">
                                <span>Techsuttle | Freelance Consultant</span>
                            </div>    
                            <div className="para_list">
                                <ul>
                                    <div className="li_head">
                                        Photoshop Layout Design
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Proficient in creating visually appealing and user-friendly designs using Adobe Photoshop.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in designing website layouts, mobile app interfaces, and digital marketing materials.
                                        </div>
                                    </li>
                                    <div className="li_head">
                                        Database Structure Design
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Skilled in designing efficient and scalable database structures.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Proficient in MYSQL and experience with NoSQL databases.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Capable of optimizing database performance and ensuring data integrity.
                                        </div>
                                    </li>
                                    <div className="li_head">
                                        Front-End Design
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Expertise in HTML5, CSS3, JavaScript, and popular frameworks like React.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in creating responsive and interactive web designs.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Strong understanding of UX/UI principles and web accessibility standards.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in configuring .htaccess files for Apache server, enhancing website security, and optimizing URL rewriting and redirection. Proficient in utilizing Postman for API development, testing RESTful services, and automating API workflows and tests. Skilled in debugging HTTP requests, analyzing responses, and integrating APIs with front-end and back-end systems
                                        </div>
                                    </li>
                                    <div className="li_head">
                                        Back-End Process Management
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Proficient in server-side languages like Node.js,  and PHP.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experienced in building robust APIs and integrating third-party services.
                                        </div>
                                    </li>
                                    <div className="li_head">
                                        Gateway Integration
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Skilled in integrating various payment gateways and understanding of transactional workflows.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Experience in implementing secure and compliant payment processing solutions.
                                        </div>
                                    </li>
                                    <div className="li_head">
                                        Admin Panel Development
                                    </div>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Expertise in developing intuitive and feature-rich admin panels for web applications.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="img">
                                            <i class="fa fa-check-circle-o"></i>
                                        </div>
                                        <div className="img_txt">
                                            Skilled in user management, data analytics, and system configuration tools.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>                
            </div>

            
        </div>
    )
}

export default Experience;