import React, { useEffect } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

const ProfileTem = (props) => {
    const navigation = useNavigate();
    useEffect(()=>{        
        media_size()     
        new_media();
      },[])

      function new_media(){
        if (window.matchMedia('(max-width: 1024px)').matches) {  
            $('.profile_section .profile_left .menu ul li').on('click',function(){ 
                $(".profile_section .profile_left").css({"left":"-250px"});
                $('.profile_section .profile_left .sidebar_button').attr("assign","0") ;
                $('.profile_section .profile_left .sidebar_button i').css({"transform":"rotate(0deg)"});
            })
        }
        if (window.matchMedia('(min-width: 1025px)').matches) {  
            $('.profile_section .profile_left .menu ul li').on('click',function(){ 
                $(".profile_section .profile_left").css({"left":"0px"});
                $('.profile_section .profile_left .sidebar_button').attr("assign","0") ;
                $('.profile_section .profile_left .sidebar_button i').css({"transform":"rotate(0deg)"});
            })
        }
      }
    
    function media_size(){

       
        //if (window.matchMedia('(max-width: 1024px)').matches) {      
                    
            $('.profile_section .profile_left .sidebar_button').on('click',function(){      
                
              if($(this).attr("assign") == 0){
                
                
                  $(".profile_section .profile_left").animate({"left":"0"});
                  $(this).attr("assign","1") ;
                  //$('.patient_portal .leftside_bar .sidebar_button i').css({"transform":"rotate(-180deg)"});
                  $('.profile_section .profile_left .sidebar_button i').animate(
                      {deg:180},
                      {
                        duration: 700,
                        step: function(now) {
                          $(this).css({ transform: 'rotate(' + now + 'deg)' });
                        }
                      }
                  );
              }else{
                $(".profile_section .profile_left").animate({"left":"-250px"});
                  $(this).attr("assign","0") ;
                  $('.profile_section .profile_left .sidebar_button i').animate(
                      {deg:0},
                      {
                        duration: 700,
                        step: function(now) {
                          $(this).css({ transform: 'rotate(' + now + 'deg)' });
                        }
                      }
                  );
              }
          })
       /*  } else{
          $(".profile_section .profile_left").css({"left":"0"});
          $('.profile_section .profile_left .sidebar_button').attr("assign","1");
        } */
    
        if (window.matchMedia('(min-width: 1025px)').matches) { 
          $(".profile_section .profile_left").css({"left":"0"});
          $('.profile_section .profile_left .sidebar_button').attr("assign","1");
        }
    }
    
    $( window ).on( "resize", function() {
      media_size_01();
      new_media();
    });
    
    function media_size_01(){
      if (window.matchMedia('(min-width: 1025px)').matches) {  
        $(".profile_section .profile_left").css({"left":"0"});
        $('.profile_section .profile_left .sidebar_button i').css({"transform":"rotate(0deg)"});
        $('.profile_section .profile_left .sidebar_button').attr("assign","0");
      }
    
      if (window.matchMedia('(max-width: 1024px)').matches) {  
        //$(".profile_section .profile_left").css({"left":"-250px"});
        $('.profile_section .profile_left .sidebar_button i').css({"transform":"rotate(180deg)"});
        $('.profile_section .profile_left .sidebar_button').attr("assign","1");
        
      }
    }
    return (
        <div className="profile_section">
            <div className="profile_left">
                <div className="sidebar_button" assign='0'>
                    <i className="fa fa-arrow-circle-o-right"></i>
                </div>
                <div className="profile_image">
                    <div className="imgs">
                        <img src="../../images/my_02.png" alt="my_02"/>
                      
                    </div>    
                                   
                </div>
                <div className="profile_user">
                    <div className="name">
                        Jaheer Hussain Abdul
                    </div>
                    <div className="designation">
                        Full Stack Developer
                    </div>
                    <div className="social_icon">
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/in/jaheer-hussain-abdul-0721811b0/" target="_blank"><i class="fa fa-linkedin"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/jaheerabdul91" target="_blank"> <i class="fa fa-instagram"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="menu">
                    <ul>
                        <li onClick={()=>navigation("/")}>Home</li>
                        <li onClick={()=>navigation("/about-me")}>About</li>
                        <li onClick={()=>navigation("/skills")}>Skills</li>
                        <li onClick={()=>navigation("/experience")}>Experience</li>
                        <li onClick={()=>navigation("/services")}>Service</li>
                        <li onClick={()=>navigation("/contact")}>Contact</li>
                        
                    </ul>
                </div>
                <div className="down_cv">
                    <a href="../cv/jaheer_abdul.pdf" download="JaheerAbdul">Download CV</a>
                </div>
            </div>
            <div className="profile_right">
                <div class="jh_bg grad">
                    <ul class="jh_square">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <div className="right_cons">
                    {props.children}
                </div>
                
            </div>
        </div>
    );
}

export default ProfileTem;