import React from "react";
import { useNavigate } from "react-router-dom";


//import images from '../../images/';

const Home = () => {
    const navi = useNavigate();
    var sd = "round.png";
    return(
        <div className="container">
            <div className="section_con">
                <div className="section_center">
                    <div className="section">
                        <div className="left">
                            <div className="my_image">
                            <picture>
                                <source media="(max-width:1024px)" srcset="../../images/my_06.png"/>
                                <img src="../../images/my_05.png" alt="" />
                            </picture> 
                                {/* <img src="../../images/my_05.png" alt="my_01"/> */}
                            </div>
                            {/* <div className="round">
                                <img src={"../../images/"+sd} alt={sd}/>
                            </div> */}
                        </div>
                        <div className="right">
                            <div className="right_center">
                                <div className="right_con">
                                    <div className="self_con">
                                        <div className="first_head">I'M</div>
                                        <div className="sub_head">Jaheer Hussain Abdul</div>
                                        <div className="sub_head_two">Full Stack Developer</div>
                                    </div>
                                    <div className="self_button">
                                        <div className="buttons">
                                            <ul>
                                                <li onClick={()=>navi("/about-me")}>About Me <div className="line"></div></li>
                                                <li onClick={()=>navi("/contact")}><i class="fas fa-user-alt"></i>Hire Me Now</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_con">
                <div className="footer_center">
                    <div className="footer">
                        CopyRight ©  jaheeradbul.in | All Rights Reserved
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;