import React,{useState} from "react";

const Contact = () =>{
    const initailValues = {
        name:"",
        email:"",
        mobile_no:"",
        textar:""
    }

    const [userDetails,setUserDetails] = useState(initailValues);
    const [formErrors,setformErrors] = useState({});
    const [insertData,setinsertData] = useState();

    const handleChange = (e)=>{
        const {name,value} = e.target;
        setUserDetails({...userDetails,[name]:value})
        setformErrors(validates(userDetails));
    }

    const validates=(values)=>{
        const errors = {};
        const regex = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if(!values.name){
            errors.name = "Please enter the name";
        }

        if(!values.email){
            errors.email = "Please enter the email Id";
        }else if(!regex.test(values.email)){
            errors.email = "Please enter the valid Emaid Id";
        }

        if(!values.mobile_no){
            errors.mobile_no = "Please enter the Mobile Number"
        }else if(values.mobile_no.length < 10){
            errors.mobile_no = "Please enter at least 10 characters. ";
        }else if(values.mobile_no.length > 10){
            errors.mobile_no = "Please enter no more than 10 characters";
        }

        if(!values.textar){
            errors.textar = "Please enter the Message";
        }

        return errors;
    }

    const handleSubmit = async() =>{
        setformErrors(validates(userDetails));
        //setIsSubmit(true);
        //console.log(userDetails);
        inserDa() 
    }

    const inserDa = async() =>{
           
        if(Object.keys(formErrors).length === 0){
            const name = document.getElementById('name').value;
            const email = document.getElementById('email').value;
            const mobile = document.getElementById('mobile_no').value; 
            const message = document.getElementById('textar').value; 

            const insertForm = {
                name:name,
                email:email,
                mobile_no:mobile,
                message:message
              };
            //console.log(JSON.stringify(insertForm))

            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(insertForm),
                headers:{
                    "Content-Type":"application/json",
                    
                },
                //redirect: 'follow'
              };

              const insertQ = await fetch("http://97.74.90.166:1003/companyuser/createuser",requestOptions);
              console.log(insertQ.message);
              const ress = await insertQ.json();
              console.log(ress);
              console.log(ress.message);
              setinsertData(ress.message);
        }
    }

    const handleUp = (e) =>{        
        setformErrors(validates(userDetails));
    }
    return (
        <div className="about">
            <div class="jh_bg grads">
                <ul class="jh_squares">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className="head">
                Get in Touch
            </div>
            <div className="details">
                <div className="block_one">
                    <div className="head_s">
                        
                    </div>
                    <div className="head_de">
                        <p>
                            Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.
                        </p>
                    </div>
                    <div className="mail">
                        <div className="image">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                        </div>
                        <div className="img_txt">
                            a.jaheer786@gmail.com
                        </div>
                    </div>
                    <div className="mail">
                        <div className="image">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div className="img_txt">
                            +91 90949 46095
                        </div>
                    </div>
                    <div className="mail">
                        <div className="image">
                        <i class="fa fa-whatsapp color-red" style={{color:"#25d366"}}></i>
                        </div>
                        <div className="img_txt">
                            +91 97911 79241
                        </div>
                    </div>
                    <div className="down_cv">
                        <a href="../cv/jaheer_abdul.pdf" download="JaheerAbdul">Download CV</a>
                    </div>
                </div>
                <div className="block_two">
                    <div className="input_box">
                        <div className="set_name">
                            Name
                        </div>
                        <div className="set_feild">
                            <input type="text" name="name" id="name" value={userDetails.name} onChange={handleChange} onKeyUp={handleUp}/>
                        </div>
                        <label>{formErrors.name}</label>
                    </div>
                    <div className="input_box">
                        <div className="set_name">
                            Email Id
                        </div>
                        <div className="set_feild">
                            <input type="text" name="email" id="email" value={userDetails.email} onChange={handleChange} onKeyUp={handleUp}/>
                        </div>
                        <label>{formErrors.email}</label>
                    </div>
                    <div className="input_box">
                        <div className="set_name">
                            Mobile Number
                        </div>
                        <div className="set_feild">
                            <input type="text" name="mobile_no" id="mobile_no" value={userDetails.mobile_no} onChange={handleChange} onKeyUp={handleUp}/>
                        </div>
                        <label>{formErrors.mobile_no}</label>
                    </div>
                    <div className="input_box">
                        <div className="set_name">
                            Message For me
                        </div>
                        <div className="set_feild">
                            <textarea name="textar" id="textar"  onChange={handleChange} onKeyUp={handleUp}>{userDetails.textar}</textarea>
                        </div>
                        <label> {formErrors.textar}</label>
                    </div>
                    <div className="input_box">
                        <div className="set_feild">
                            <input type="button" value="Send Message" onClick={handleSubmit}/>
                        </div>
                    </div>
                    {
                                
                                Array.isArray(insertData) && insertData.length > 0 ? 
                                ( insertData.map((singleData,i) => { return <h1>{singleData.message}</h1>} ) ) : 
                                typeof insertData === 'string' ?
                                (
                                    
                                        <label className="label">{insertData}</label>
                                        
                                    
                                ):
                                <label></label>
                            }
                </div>
            </div>
        </div>
    );
}

export default Contact;