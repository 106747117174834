import React from "react";

const AppRoutes = ({layout:Layout,component:Component}) =>{
    Layout = Layout === undefined ? (props) => <>{props.children}</> :Layout;
    return(
        <Layout>
            <Component/>
        </Layout>
    );
}

export default AppRoutes;