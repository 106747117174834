import React from "react";

const Skills = () =>{
    return (
        <div className="about">
            <div class="jh_bg grads">
                <ul class="jh_squares">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className="head">
                Technical Skills
            </div>
            <div className="skill_set">
            <div className="set_one">
                   <div className="set">
                        <div className="set_head">
                            Front-End
                        </div>
                        <div className="set_list">
                            <ul>
                                <li>
                                    <div className="tech_h">HTML</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"90%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">CSS</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"90%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Java Script</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Jquery</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">AJAX</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"75%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">React Js</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="set_one">
                   <div className="set">
                        <div className="set_head">
                            Back-End & Database
                        </div>
                        <div className="set_list">
                            <ul>
                                <li>
                                    <div className="tech_h">PHP</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">MYSQL</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Mongo DB</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"70%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">API</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"65%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">NODE JS</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"60%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Express Js</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"60%"}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                
                <div className="set_one">
                   <div className="set">
                        <div className="set_head">
                            Version Control
                        </div>
                        <div className="set_list">
                            <ul>
                                <li>
                                    <div className="tech_h">Git And GitHub</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"70%"}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="set_one">
                   <div className="set">
                        <div className="set_head">
                            Designing Tools
                        </div>
                        <div className="set_list">
                            <ul>
                                <li>
                                    <div className="tech_h">Adobe Photoshop</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"80%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Adobe Illustrator</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"75%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Adobe Indesign</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"75%"}}></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="tech_h">Corel Draw</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"50%"}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                

                <div className="set_one">
                   <div className="set">
                        <div className="set_head">
                            Editing Tool
                        </div>
                        <div className="set_list">
                            <ul>
                                <li>
                                    <div className="tech_h">Premiere Pro</div>
                                    <div class="progress ">
                                        <div class="percentage" style={{width:"50%"}}></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="head">
                Education
            </div>

            <div className="years_cols">
                <div className="cols_one">
                    <div className="time_line">
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2020-2022</span>
                                <span>89%</span>
                            </div>
                            <div className="name">
                                <span>Master of Science, Information Technology (Gold Medal)</span>
                            </div> 
                            <div className="company">
                                <span>Vels University, Pallavaram, Chennai </span>
                            </div>    
                        </div>
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2017 - 2020</span>
                                <span>86%</span>
                            </div>
                            <div className="name">
                                <span>Bachelor Of Science, Visual Communication </span>
                            </div> 
                            <div className="company">
                                <span>Bharath University, Agharam Road Selaiyur, Chennai  </span>
                            </div>    
                        </div>

                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2012 - 2013</span>
                                <span>80%</span>
                            </div>
                            <div className="name">
                                <span>Certificate In Graphic & Web Design</span>
                            </div> 
                            <div className="company">
                                <span>Arena Animation,T.nagar, Chennai</span>
                            </div>    
                        </div>
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2007 - 2008</span>
                                <span>71%</span>
                            </div>
                            <div className="name">
                                <span>H.SC</span>
                            </div> 
                            <div className="company">
                                <span>T.K.S.M.Hr.Sec School</span>
                            </div>    
                        </div>
                        <div className="time_line_dot">
                            <div className="date_time">
                                <span>2005 - 2006</span>
                                <span>70%</span>
                            </div>
                            <div className="name">
                                <span>S.S.L.C</span>
                            </div> 
                            <div className="company">
                                <span>T.K.S.M.Hr.Sec School</span>
                            </div>    
                        </div>
                    </div>
                </div>                
            </div>

            <div className="head">
                Key Achievements
            </div>

            <div className="skill_set">
                <div className="set_two">
                   <div className="set">
                        <div className="set_list">
                            <ul>
                                <li>Led a project that improved patient appointment scheduling online, increasing user satisfaction by 40%.</li>
                                <li>Successfully integrated a telemedicine platform using API integrations, enhancing patient access to healthcare services.</li>
                                <li>Developed a mobile application for patient records management, significantly improving data accessibility for medical staff.</li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="head">
                Key Projects
            </div>

            <div className="skill_set">
                <div className="set_two">
                   <div className="set">
                        <div className="set_list">
                            <ul>
                                <li>Developed an e-commerce platform, resulting in a 30% increase in client sales.</li>
                                <li>Implemented a custom content management system, enhancing the client's ability to update website content easily.</li>
                                <li>Revamped a major client’s website, improving navigation and reducing load time by 25%.</li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Skills;