
import './App.css';
import './css/style.css';
import './css/fonts.css';

import Main_nav from './navigation/main_nav';

import {Helmet} from 'react-helmet';


function App() {
  
  

  return (
    <div className="App">
     
      <Helmet>
        <title>Jaheer Abdul | Profile</title>
        <meta name="description" content="My Profile Page created using MongoDb, Express Js, React js, Node Js" />
        <meta name="keywords" content="react, node js, mern stack, express js, Mongodb, Mongoose" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Jaheer Hussain Abdul" />
        <meta property="og:title" content="Jaheer Hussain Abdul" />
        <meta property="og:description" content="My Profile Page created using MongoDb, Express Js, React js, Node Js" />
        <meta property="og:image" content="http://jaheerabdul.in/images/logo.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="Jaheer Abdul | Profile" />
        <meta name="twitter:description" content="My Profile Page created using MongoDb, Express Js, React js, Node Js" />
        <meta name="twitter:image" content="http://jaheerabdul.in/images/logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
     
      <Main_nav/>
    </div>
  );
}

export default App;
