import React from "react";

const About = () =>{
    return (
        <div className="about">
            <div class="jh_bg grads">
                    <ul class="jh_squares">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            <div className="head">
                About Me
            </div>
            <div className="me_a">
                <div className="intro">
                    <p>With over 9 years of comprehensive experience in web development, I am a seasoned Full Stack Developer, proficient in a wide array of technologies that span the breadth of website and application development. My expertise includes the development of dynamic, user-centric websites and full-stack applications using HTML, CSS, JavaScript, jQuery, PHP, MySQL, and the MERN stack (MongoDB, Express.js, React, Node.js), integrated with AJAX for seamless user experiences. My career has been dedicated to creating efficient, scalable web applications, and I have a proven track record of delivering high-quality projects that meet both user needs and business goals.</p>
                    <div className="p_head">Technical Skills:</div>
                    <p>
                        <span>Front-End Development:</span> Skilled in HTML5, CSS3, and JavaScript, with a deep understanding of responsive design principles and cross-browser compatibility. Proficient in using jQuery for adding interactive features to web pages, enhancing user experience without compromising website performance.
                    </p>
                    <p>
                        <span>Back-End Development:</span> Experienced in PHP programming, creating robust backend solutions, and integrating with MySQL databases for data-driven applications. Familiar with developing REST ful APIs and working with AJAX for real-time data processing and page updates without reloading.
                    </p>
                    <p>
                        <span>Full Stack Application Development:</span> Proficient in using the MERN stack for developing scalable full-stack applications. Experience includes building applications from the ground up, managing server-side logic with Node.js, developing user interfaces with React, and handling data management with MongoDB and Express.js.
                    </p>
                    <p>
                        <span>Version Control and Collaboration:</span> Experienced in using Git for version control, facilitating collaboration among teams, and ensuring the integrity and security of the project codebase.
                    </p>
                    <p>
                        <span>Project Management:</span> Ensuring efficient project execution and delivery within timelines and budget constraints.
                    </p>
                </div>

                <div className="intro_right">
                    <div className="part_one">
                        <ul>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Birthday
                                    </div>
                                    <div className="col_two">
                                        09 February 1991 
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Email
                                    </div>
                                    <div className="col_two">
                                        jaheer_hussain@ymail.com
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Mobile
                                    </div>
                                    <div className="col_two">
                                        +91 9094946095 
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Nationality
                                    </div>
                                    <div className="col_two">
                                        Indian
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Marital Status
                                    </div>
                                    <div className="col_two">
                                        Married
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Gender
                                    </div>
                                    <div className="col_two">
                                        Male
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">
                                    <div className="col_one">
                                        Language
                                    </div>
                                    <div className="col_two">
                                        English, Tamil
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="part_head">
                        My Interest
                    </div>

                    <div className="part_two">
                        <ul>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Gaming
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Driving
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Photography
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Research Related To My Subject
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Travelling
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Chat
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Cricket, Shuttle
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Computer programming
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="li_con">                                    
                                    <div className="col_two">
                                        Shopping
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default About;